<template>
  <div>
     <vue-header title="身份认证" isReturn="true" :fixed="true" />
     <div class="certificate-ios">
         <div class="certificate-box">
            <van-uploader :after-read="afterRead1" :max-count="1" v-if='!upload1'>
                <div class="certificate flex flex-x flex-y">
                    <div>
                        <img src="../../../images/photo.png"/>
                        <div class="certificate-text">点击上传医师资格证书</div>
                    </div>
                </div>
            </van-uploader>
            <div v-else class="certificate-img flex flex-x">
                <img :src="upload1"/>
                <div class="certificate-delete" @click="()=>change({
                    type:1
                })">×</div>
            </div>
         </div>
         <div class="certificate-box">
            <van-uploader :after-read="afterRead2" :max-count="1" v-if='!upload2'>
                <div class="certificate flex flex-x flex-y">
                    <div>
                        <img src="../../../images/photo.png"/>
                        <div class="certificate-text">点击上传医师执业证书</div>
                    </div>
                </div>
            </van-uploader>
            <div v-else class="certificate-img flex flex-x">
                <img :src="upload2"/>
                <div class="certificate-delete" @click="()=>change({
                    type:2
                })">×</div>
            </div>
         </div>
         <div class="certificate-submit fulfil" v-if="upload1&&upload2" @click="uploadSubmit">完成</div>
         <div class="certificate-submit" v-else>完成</div>
         <div class="certificate-tips">温馨提示：请在光线充足的情况下进行拍摄，避免因照片不清晰而影响您的审核结果。</div>
     </div>
  </div>
</template>
<script>
import { mapActions , mapState ,mapMutations } from 'vuex';
import vueHeader from '@/components/header' 
export default {
  name: 'upload',
  components:{
    vueHeader
  },
  data(){
      return {
         show:false,
      }
  },
//   created:function(){
//       this.userData(this);
//   },
  destroyed: function () {
      this.closeFun();
  },
  computed:{
      ...mapState('upload',['upload1','upload2']),
  },
  methods:{
      back(){
          window.history.back(-1);
      },
      afterRead1(file) {
         this.uploadData({
             type:1,
             file:file.file
         });
      },
      afterRead2(file) {
         this.uploadData({
             type:2,
             file:file.file
         });
      },
      uploadSubmit(){
          this.submitData(this);
      },
      ...mapMutations('upload',['change','closeFun']),
      ...mapActions('upload',['uploadData','submitData'])
  }
}
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'upload';
</style>